<template>
  <div>
    <v-form>
      <p class="mb-5">
        Predict the major product (if there is one) for the dehydration reaction of 2-butanol using
        sulfuric acid shown below. Justify your answer and mention the mechanism of the reaction.
      </p>

      <p class="mb-5">
        <v-img :src="imageName" style="max-width: 550px" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">I think that because:</p>
      <s-textarea v-model="inputs.input2" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemLC241PS9Q3',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options: [
        {text: 'Product (A) is the major product', value: 'a'},
        {text: 'Product (B) is the major product', value: 'b'},
        {text: 'Products (A) and (B) are produced in equal amounts', value: 'c'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/LC241/q3-img.png';
    },
  },
};
</script>
